@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-ExtraLightItalic.woff2")
      format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-ExtraLightItalic.woff")
      format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-BoldItalic.woff2")
      format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-ExtraLight.woff2")
      format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-ExtraLightItalic.woff2")
      format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-ExtraLightItalic.woff")
      format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-ExtraLight.woff2")
      format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-Black.woff2") format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-Bold.woff2") format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-BoldItalic.woff2")
      format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-Black.woff2") format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-Bold.woff2") format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-Italic.woff2") format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-LightItalic.woff2")
      format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-Light.woff2") format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-LightItalic.woff2")
      format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-Regular.woff2") format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-Italic.woff2") format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-SemiBold.woff2") format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./../../Assests/fonts/TitilliumWeb-SemiBoldItalic.woff2")
      format("woff2"),
    url("./../../Assests/fonts/TitilliumWeb-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
