@use "../../Styles/Partials/mixins" as *;
@use "../../Styles/Partials/typography" as *;
@use "../../Styles/Partials/variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
}
.home {
  @include PageColor;

  &__container {
    @include container;
  }

  &__logoContainer {
    @include logoContainer;
  }
  &__link1 {
    :hover {
      background-color: #699e89;
      border: 0.1rem solid rgb(244, 239, 234);
      border-radius: 0.8rem;
    }
    display: flex;
    align-items: center;
    margin: 0;
  }
  &__logo {
    border-radius: 0.8rem;
    width: 3rem;
    height: 3rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.489),
      0 1px rgba(255, 255, 255, 0.3);
  }
  &__facts {
    @include factsButton;
  }
  &__fun {
    margin: 0;
    padding-top: 1rem;
    font-weight: 600;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  &__circles {
    display: flex;
    align-items: center;
    padding-top: 1rem;
  }
  &__content {
    margin: 0;
    padding-top: 1rem;
    height: 20rem;
    width: 16rem;
    font-weight: 300;
    font-style: italic;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  &__account {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
  }
  &__already {
    font-weight: 300;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  &__CArrangement {
    display: flex;
  }
  &__begin {
    text-decoration: none;
  }

  &__link {
    margin: 0;
    text-decoration: none;
  }
  &__beginButton {
    @include beginButton;
  }
}
