@mixin tablet {
  @media screen and (min-width: 767px) {
    @content;
  }
}
@mixin desktop {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

// HOME PAGE
// ............................................................
@mixin PageColor {
  background-color: rgb(244, 239, 234);
  color: #333333;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 300;
  font-style: normal;
  font-size: 1.5rem;
  justify-content: center;
}

@mixin container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  height: 100vh;
  border: 0.2rem solid rgba(255, 255, 255, 0.452);
  border-radius: 0.9rem;
  border-style: ridge;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.29);
}

@mixin logoContainer {
  width: 20rem;
  height: 4rem;
  border-radius: 0.8rem;
  background-color: #ca8935;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-bottom: 0.1rem solid #699e89;
}

//small circles for the fun facts buttons
@mixin funFactsCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  background-color: #ca89358c;
  border-radius: 10rem;
  border: 1.8px solid #ca8935;
  margin: 0.5rem;
  transition: all 0.2s ease;
  flex-wrap: nowrap;
  &:hover {
    background-color: #ca8935;
  }
  &:focus {
    background-color: #ca8935;
  }
}
// Title
@mixin Title {
  margin-bottom: 2rem;
  color: #333333;
  margin-top: 2rem;
  font-weight: 600;
  font-style: normal;
  font-size: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
//Home Page Begin Button
@mixin beginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 4rem;
  margin: 0;
  color: black;
  background-color: #ca8935;
  border-top: 0.1rem solid #699e89;
  border-radius: 0.5rem;
  font-weight: 100;
  font-style: normal;
  font-size: 1.5rem;
  &:hover {
    border-top: 0.1rem solid #ca8935;
    background-color: #699e89;
    color: rgb(244, 239, 234);
  }
  @content;
}

@mixin factsButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18rem;
  height: 22rem;
  background-color: rgb(244, 239, 234);
  border: 2px solid #ca89358c;
  border-radius: 2rem;
  font-weight: 600;
}

//TOTAL SCORE PAGE MIXINS
// ...................................................
// Score
@mixin ScoreDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12rem;
  height: 4rem;
  margin: 0;
  color: #333333;
  font-weight: 600;
  font-style: normal;
  font-size: 1.5rem;
  margin-top: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.489),
    0 1px rgba(255, 255, 255, 0.3);

  background-color: #699e89;
  border: 0.1rem solid rgb(244, 239, 234);
  border-radius: 0.8rem;

  :hover {
    background-color: #ca8935;
    border: 0.1rem solid rgb(244, 239, 234);
    border-radius: 0.8rem;
  }
}
