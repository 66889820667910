@use "../../Styles/Partials/mixins" as *;
@use "../../Styles/Partials/typography" as *;
@use "../../Styles/Partials/variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
}
.next {
  &__small {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 3rem;
    height: 2rem;
    border-radius: 0.5rem;
    border: 1.8px solid #699e89;
    transition: all 0.2s ease;
    font-weight: 300;
    font-style: normal;
    font-size: 1rem;
    color: white;
    background-color: #699e89;
  }
}
