@use "../../Styles/Partials/mixins" as *;
@use "../../Styles/Partials/typography" as *;
@use "../../Styles/Partials/variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
}
.numpictures {
  &__npicture {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    cursor: pointer;
    width: 15.5rem;
    height: 15.5rem;
    margin: 0.6rem;
    border-radius: 100%;
    border: 2px solid#ca8935;
    transition: all 0.2s ease;
    font-weight: 300;
    font-style: normal;
    font-size: 1.5rem;
    color: #333333;
    background-position-x: center;

    &--incorrect {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;

      cursor: pointer;
      width: 15.5rem;
      height: 15.5rem;
      margin: 0.6rem;
      border-radius: 100%;
      border: 2px solid#be250a;
      transition: all 0.2s ease;
      font-weight: 300;
      font-style: normal;
      font-size: 1.5rem;
      color: #333333;
      background-position-x: center;
    }
  }

  &__nuer {
    font-weight: 600;
    font-style: normal;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
}
