@use "../../Styles/Partials/mixins" as *;
@use "../../Styles/Partials/typography" as *;
@use "../../Styles/Partials/variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
}
.box {
  &__squares {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 18rem;
    height: 4rem;
    margin: 0.8rem;
    border-radius: 0.5rem;
    border: 1.8px solid #ca8935;
    transition: all 0.2s ease;

    font-weight: 300;
    font-style: normal;
    font-size: 1.5rem;
    color: #333333;
    background-color: transparent;
    &:hover {
      background-color: #ca8935;
    }
    &--correct {
      &:focus {
        border: 1.8px solid #699e89;
        background-color: #699e89;
      }
    }
    &--incorrect {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 18rem;
      height: 5rem;
      margin: 0.8rem;
      border-radius: 0.5rem;
      border: 1.8px solid #ca8935;
      transition: all 0.2s ease;
      font-weight: 300;
      font-style: normal;
      font-size: 1.5rem;
      color: #333333;
      background-color: transparent;
      &:focus {
        border: 1.8px solid #be250a;
        background-color: #be250a;
      }
    }
  }
}
