@use "../../Styles/Partials/mixins" as *;
@use "../../Styles/Partials/typography" as *;
@use "../../Styles/Partials/variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
}
.letters {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 2.9rem;
  height: 2.9rem;
  margin: 0.2rem;
  border-radius: 0.5rem;
  border: 1.8px solid #ca8935;
  transition: all 0.2s ease;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.489),
    0 1px rgba(255, 255, 255, 0.3);

  color: #333333;
  background-color: #699e89;
  &:hover {
    border: 1.8px solid #699e89;
    background-color: white;
  }
  &:focus {
    border: 1.8px solid #699e89;
    background-color: #699e89;
  }
}
