@use "../../Styles/Partials/mixins" as *;
@use "../../Styles/Partials/typography" as *;
@use "../../Styles/Partials/variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}
// body {
//   margin: 0;
//   font-family: "Titillium Web", Tahoma, sans-serif;
// }

body {
  font: 13px/20px "Lucida Grande", Tahoma, Verdana, sans-serif;
  color: #404040;
  background: #2a2a2a;
}

.container {
  margin: 1rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.container .progress {
  width: 7.4rem;
  height: 1rem;
}

.progress {
  padding: 4px;

  border-radius: 6px;

  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.489),
    0 1px rgba(255, 255, 255, 0.3);
}

.progress-bar {
  height: 0.5rem;
  border-radius: 4px;

  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  transition: 0.2s linear;
  transition-property: width, background-color;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25),
    inset 0 1px rgba(255, 255, 255, 0.1);
}

#five:checked ~ .progress > .progress-bar {
  width: 5%;
  height: 0.5rem;
  background-color: #f63a0f;
}

.radio {
  display: none;
}

.label {
  display: inline-block;
  margin: 0 5px;
  color: #aaa;
  border-radius: 3px;
  cursor: pointer;

  &__left {
    display: flex;
    margin: 0 5px;
    width: 1rem;
    cursor: pointer;
  }
  &__right {
    display: flex;
    margin: 0 5px;
    width: 1rem;
    cursor: pointer;
  }
}

.radio:checked + .label {
  height: 1rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0;
  color: white;
  background: rgba(0, 0, 0, 0.25);
}
