@use "../../Styles/Partials/mixins" as *;
@use "../../Styles/Partials/typography" as *;
@use "../../Styles/Partials/variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Titillium Web", Tahoma, sans-serif;
}

.anatomy {
  @include PageColor;

  &__container {
    @include container;
  }

  &__logoContainer {
    @include logoContainer;
  }
  &__title {
    @include Title;
  }
  &__box {
    margin-top: 2rem;
    font-weight: 600;
    font-style: normal;
    font-size: 2rem;
  }
  &__pictures {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin: 0;
  }

  &__thokNaath {
    font-weight: 600;
    font-style: normal;
    font-size: 1.5rem;
    margin: 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  &__thokNaathPronunciation {
    font-weight: 300;
    font-style: italic;
    font-size: 1.2rem;
    color: #333333;
    margin: 0;
    margin-right: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  &__content {
    width: 18rem;
    display: flex;
    align-items: end;
    justify-content: space-between;
  }
  &__next {
    display: flex;
    justify-content: end;
    align-items: end;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  &__ScoreNext {
    display: flex;
    flex-direction: column;
    align-items: end;
    font-weight: 300;
    font-style: italic;
    font-size: 1.2rem;
  }
  &__next1 {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
}
