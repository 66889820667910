@use "../../Styles/Partials/mixins" as *;
@use "../../Styles/Partials/typography" as *;
@use "../../Styles/Partials/variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
}
.list {
  &__listCategories {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    width: 18rem;
    height: 8rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    border: 1.8px solid #ca8935;
    transition: all 0.2s ease;

    font-weight: 300;
    font-style: normal;
    font-size: 1.5rem;
    color: #333333;
    background-color: white;
    &:hover {
      background-color: #699e89;
      color: white;
    }

    &__images {
      height: 5rem;
      width: 5rem;
    }
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.489),
      0 1px rgba(255, 255, 255, 0.3);
    // box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.489),
    //   0 1px rgba(255, 255, 255, 0.3);
    @include tablet {
      // width: 75%;
      // align-content: end;
      // margin-left: 1rem;
    }
    @include desktop {
      margin-left: 2rem;
    }
  }
}
